<template>
  <div class="not__found">
    <img draggable="false" :src="require('../assets/images/404.svg')" alt="404" />

    <h1 class="title">Такой страницы нет</h1>

    <span class="subtitle">
      Возможно, эту страницу уже удалили, или в вашей ссылке ошибка
    </span>

    <div class="buttons m-center flex-col">
      <VButton
        text="Вернуться назад"
        color="primary"
        size="xl"
        @click.native="$router.go(-1)"
      />

      <VButton
        text="Вернуться на главную"
        size="xl"
        @click.native="$router.push({ name: 'Order' })"
      />
    </div>
  </div>
</template>

<script>

import VButton from '@/components/simple/button/VButton'

export default {
  components: {
    VButton
  }
}
</script>
